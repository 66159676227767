import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { innerWidth, screen } from "../components/variables";
import ButtonUnderlined from "../components/buttons/button-underlined";
import {
  IconCircleDarkPurpleMd,
  IconCircleLightPurpleMd,
  IconCircleCyanMd,
} from "../components/circles";

const Wrapper = styled.div`
  min-height: calc(100vh - 192px);
  position: relative;
  overflow: hidden;

  .icon-circle {
    z-index: 2;

    &--cyan {
      display: none;
      @media ${screen.xxsmall} {
        display: block;
        bottom: -75px;
        width: 250px;
        left: 100px;
      }
    }

    &--dark-purple {
      display: none;
      @media ${screen.xxsmall} {
        display: block;
        bottom: 200px;
        left: 75px;
        width: 60px;
      }
    }
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto 110px auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .heading {
    font-weight: 900;
    line-height: 1.22;
    position: relative;
    font-size: 2rem;
    @media ${screen.xxsmall} {
      font-size: 3.8rem;
    }
    @media ${screen.small} {
      font-size: 5rem;
      line-height: 1.09;
    }

    span {
      display: block;
    }
  }

  .description {
    font-weight: 700;
    line-height: 1.4;
    margin: 30px 0;
    font-size: 1.52rem;
    @media ${screen.small} {
      font-size: 1.55rem;
    }
  }

  .featured-img {
    max-width: 883px;
    margin: 60px 0 0 auto;
    position: relative;
    @media ${screen.medium} {
      margin: -72px 0 0 auto;
    }

    .icon-circle {
      width: 132px;
      right: -100px;
      top: -132px;
      @media ${screen.xxsmall} {
        width: 230px;
        right: -150px;
        top: 75px;
      }
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout is404Page>
      <SEO title="404: Not found" />
      <Wrapper>
        <div className="inner-wrapper">
          <IconCircleCyanMd className="icon-circle icon-circle--cyan" />
          <IconCircleDarkPurpleMd className="icon-circle icon-circle--dark-purple" />

          <h2 className="heading">
            You’re barking <span>up the wrong tree!</span>
          </h2>
          <p className="description">You won’t find what you need here</p>

          <ButtonUnderlined label="TAKE ME HOME" />

          <div className="featured-img">
            <StaticImage
              src="../images/k9-swim-four-o-four-dog.png"
              alt="Lost"
            />

            <IconCircleLightPurpleMd className="icon-circle" />
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
